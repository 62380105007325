.metamask-error {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 110;
  flex-direction: column;
  font-size: 25px;
  width: 100%;
  color: #fff;
  background-color: lightblue;

  .extensions {
    display: flex;
    justify-content: center;
    align-items: center; }
  .text {
    font-weight: bold;
    color: #6D8DEA;
    font-size: 12px; }
  .extension {
    color: #f00;
    font-size: 22px;
    margin: 0 10px; }
  .img-wrap {
    img {
      width: 45%; } }
  p {
    c {}
    margin-block-end: 0;
    margin-block-start: 10px;
    font-size: 16px;

    a {
      color: blue;
      text-decoration: underline; } } }
