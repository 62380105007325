.exchange-container {
	padding: 40px 0;
	.flying-form {
		p {
			text-align: left; } }
	.square {
		font-weight: 300;
		display: flex;
		flex-direction: column;
		height: 65px;
		justify-content: center;
		padding: 0 20px;
		font-size: 14px;
		margin-bottom: 10px;
		background-color: #040446; }

	.nav-items-container {
		.nav-item {
			text-transform: capitalize;
			color: #aaaaaa;
			transition: all .3s ease;
			cursor: pointer;
			&.active {
				color: #ffffff; }
			@media only screen and (max-width: 768px) {
				margin: {
					left: 2px;
					right: 2px; }

				height: 40px; } } }
	.tickets-info-container {
		margin-top: 40px;
		@media only screen and (max-width: 768px) {
			margin-top: 0px; }
		.tickets-info-item {
			text-align: center;
			&:last-child {
				margin-bottom: 0; }
			@media only screen and (max-width: 450px) {
				height: 100px; }
			&-value {
				line-height: 1;
				font-size: 25px;
				margin-bottom: 3px; }
			&-description {
				color: #aaa;
				margin-top: -3px; } } }
	.exchange-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.title {
			margin: auto;
			margin-bottom: 40px;
			display: inline-block;
			font-weight: 300;
			font-size: 23px;
			text-align: center;
			position: relative;
			text-transform: uppercase;
			display: none;

			&:after {
				z-index: -1;
				position: absolute;
				bottom: 6px;
				left: -2%;
				width: 104%;
				height: 4px;
				background-color: #0300A3;
				content: ""; } }
		.link {
			background-image: linear-gradient(to right, #F70CA0, #F76B1C);
			color: #ffffff;
			text-decoration: none;
			padding: 10px 30px;
			text-align: center;
			border-radius: 10em;
			cursor: pointer; }
		.ticket-wrap {
			width: 100%;
			padding-top: 5px;
			padding-bottom: 10px;
			padding-left: 5px;
			padding-right: 5px;
			background-color: #3E404D; }
		.ticket {
			background-image: linear-gradient(to right, #F70CA0, #F76B1C);
			box-shadow: 5px 5px 10px 1px rgba(#000, .4);
			padding: 0 15%;
			overflow: hidden;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			&-value {
				text-overflow: ellipsis;
				overflow: hidden; }
			&:after, &:before {
				right: 20%;
				position: absolute;
				height: 15px;
				width: 30px;
				background-color: #3E404D;
				content: ""; }
			&:after {
				top: -1px;
				border-bottom-left-radius: 10em;
				border-bottom-right-radius: 10em; }
			&:before {
				background-color: #25262E;
				bottom: -1px;
				border-top-left-radius: 10em;
				border-top-right-radius: 10em; }
			&-description {
				text-transform: uppercase;
				font-weight: 300;
				font-size: 25px;
				margin-bottom: 10px; }
			&-value {
				font-size: 25px;
				span {
					font-size: 18px;
					margin-left: 10px; } } }
		&.pot {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			> div {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center; }
			.launch-wrap {
				display: flex !important; }
			.drawing {
				margin-top: 30px;
				font-size: 30px;
				text-transform: uppercase; }
			.launch {
				.launch-timer {
					line-height: 1;
					font-size: 80px;
					@media only screen and (max-width: 576px) {
						font-size: 60px; } }
				.launch-text {
					display: none; }
				.button {
					display: none; } }
			.link {
				margin: {
					top: 20px;
					left: auto;
					right: auto; } }
			.ticket-wrap {
				padding: 20px;
				.ticket {
					position: relative;
					height: 250px;
					@media only screen and (max-width: 576px) {
						height: 150px;
						background-size: 100% 100%;
						.ticket-description {
							font-size: 18px; }
						.ticket-value {
							font-size: 25px;
							span {
								margin-left: 10px; } } }
					&-value {
						font-size: 45px;
						span {
							font-size: 25px;
							margin-left: 30px; } } } } }
		&.prizes {
			.title {
				margin-bottom: 0; }
			.ticket {
				height: 100px;
				background: linear-gradient(to right, #0A8EFC, #4A13EA);
				&.first {
					height: 130px;
					background: linear-gradient(to right, #FD0786, #F76A1E); }
				@media only screen and (max-width: 576px) {
					background-size: 100% 100%; } }
			.prize-index {
				font-size: 18px;
				text-align: center;
				position: relative;
				&:after {
					position: absolute;
					bottom: 4px;
					left: -2%;
					width: 104%;
					background-color: #0300A3;
					height: 7px;
					content: "";
					z-index: -1; }
				margin: {
					top: 25px;
					bottom: 10px;
					left: auto;
					right: auto; } } }
		&.history {
			width: 100%;
			.history-top {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: rgba(#fff, .1);
				padding: 26px 0;

				.winner {
					text-transform: capitalize;
					color: #cccccc;
					margin: {
						top: 10px;
						bottom: 10px; } }
				.round {
					font-size: 20px;
					text-transform: uppercase;
					font-weight: 300; }
				.control {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.chevron {
						margin: 0 10px;
						height: 30px;
						width: 30px;
						border: 1px solid #ffffff;
						border-radius: 10em;
						display: flex;
						align-items: center;
						justify-content: center;
						@media only screen and (max-width: 550px) {
							margin: 0 5px;
							font-size: 10px;
							height: 20px;
							width: 20px; } } }
				.ticket-wrap {
					margin: 20px 0;
					width: 60%;
					height: 140px;
					position: relative;
					@media only screen and (max-width: 550px) {
						width: 85%; }
					.ticket {
						height: 100%;
						background: linear-gradient(to right, #FD0686, #F33246);
						&:after {
							top: -1px; }
						&:before {
							bottom: -3px; } } }
				.prize {
					color: #F72060;
					min-height: 24px; } }
			.history-bottom {
				@media only screen and (max-width: 550px) {
					overflow: auto; }
				.table-wrap {
					min-width: 510px; }
				.winner {
					white-space: nowrap;
					min-height: 44px;
					text-transform: capitalize;
					text-align: center;
					font-weight: 400;
					color: #000000;
					background-color: #4B39E2;
					padding: 10px;
					a {
						font-weight: 400;
						color: #000; }
					> .row {
						div {
							text-overflow: ellipsis;
							overflow: hidden; } }
					@media only screen and (max-width: 990px) {
						font-size: 14px; }
					&:nth-child(2n) {
						background-color: #5742F3; } } } }
		&.buy-tickets {
			.circled {
				height: 55px;
				flex-direction: row;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: #000074;
				position: relative;
				.currency-img {
					height: 35px; }
				.label {
					position: absolute;
					top: -23px;
					font-size: 14px;
					text-transform: uppercase;
					left: 0; } }

			.bet-container {
				text-transform: uppercase;
				@media only screen and (max-width: 576px) {
					flex-direction: column;
					height: auto;
					justify-content: space-between; }
				.input-wrap {
					padding: 0 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					@media only screen and (max-width: 576px) {
						position: relative;
						height: 55px;
						&:after {
							position: absolute;
							bottom: 0px;
							left: 0;
							width: 100%;
							height: 1px;
							background-color: #ffffff;
							content: ""; } }
					input {
						font-family: "gotham";
						width: 100%;
						outline: none;
						border: none;
						background-color: transparent;
						color: #ffffff;
						font-size: 25px;
						line-height: 1;
						font-weight: bold;
						letter-spacing: 1.2px;
						text-align: center; } }
				.controls {
					display: flex;
					justify-content: space-between;
					@media only screen and (max-width: 576px) {
						height: 40px; }
					.control {
						text-transform: uppercase;
						cursor: pointer;
						width: 100%;
						text-align: center;
						position: relative;
						padding: 5px 0;
						font-weight: bold;
						@media only screen and (max-width: 576px) {
							&:first-child {
								&:before {
									display: none; } } }
						&:before {
							position: absolute;
							left: 0;
							top: 0;
							height: 100%;
							width: 1px;
							background-color: #ffffff;
							content: ""; } } }
				.tickets-input-wrap {
					position: relative;
					input {
						height: 100%;
						border: none;
						font-size: 20px;
						color: #ffffff;
						padding: 10px 30px;
						outline: none;
						background-color: transparent; }
					.label {
						position: absolute;
						bottom: 5px;
						right: 5px;
						font-size: 13px; } }
				.controls {
					display: flex;
					background-color: #000074;
					width: 100%;
					justify-content: center;
					align-items: center;
					.control {
						position: relative;
						cursor: pointer;
						padding: {
							left: 10px;
							right: 10px; }
						&:after {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 1px;
							background-color: #fff;
							content: ""; }
						&:hover {
							color: #F71B8C; }
						&:first-child {
							&:after {
								display: none; } } } } }
			.bottom {
				margin-top: 10px;
				width: 100%;
				.button {
					margin-top: 10px; }
				.info-item {
					width: 100%;
					background-color: #000074;
					display: flex;
					flex-direction: column;
					padding: 30px 10px;
					justify-content: center;
					align-items: center;
					&-value {
						font-size: 30px; }
					@media only screen and (max-width: 574px) {
						padding: 10px;
						margin-top: 5px; } } } } } }


