.decoration {
	width: 100%;
	img {
		width: 70%;
		@media only screen and (max-width: 1200px) {
			width: 100%; } } }
[name='about-us'] .description-container {
	padding: {
		top: 70px;
		bottom: 0; }
	text-align: center;
	h1 {
		text-transform: uppercase;
		font-size: 32px;
		letter-spacing: 1.2px;
		font-weight: 300;
		margin: 0;
		font-weight: bold;
		margin-top: 20px; }
	p {
		margin-top: 0;
		font-size: 15px; }
	.shit {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		@media only screen and (max-width: 768px) {
			flex-direction: column; }
		.winter-project {
			margin-top: 30px;
			flex: 3;
			display: flex;
			align-items: center;
			position: relative;
			@media only screen and (max-width: 768px) {
				width: 100%; }
			&-circle {
				width: 180px;
				height: 180px;
				padding: 50px;
				font-size: 28px;
				line-height: 1.2;
				font-weight: 400;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				background: radial-gradient(#1469E4, #53BDED);
				@media only screen and (max-width: 768px) {
					width: 100%;
					border-radius: 10px;
					height: auto;
					padding: 10px;
					font-size: 24px;
					background: transparent;
					text-decoration: underline; }
				&:before {
					left: 90px;
					top: 0;
					height: 100%;
					width: 400px;
					position: absolute;
					background-image: url('../../assets/spectre1.png');
					background-size: 100% 100%;
					content: "";
					@media only screen and (max-width: 1200px) {
						width: 450px; }
					@media only screen and (max-width: 992px) {
						width: 500px; }
					@media only screen and (max-width: 768px) {
						display: none; } } } }
		.projects {
			flex: 3;
			display: flex;
			justify-content: space-between;
			@media only screen and (max-width: 768px) {
				width: 100%; }
			.project {
				width: 100%;
				position: relative;
				img {
					width: 55px; }
				&-description {
					font-size: 13px;
					line-height: 1.3;
					position: absolute;
					width: 90%;
					top: 100%;
					left: 5%; } } } }
	.typer-container {
		margin-top: 70px;
		min-height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		.typer {} }

	.launch {
		margin-top: 70px;
		flex-direction: column;
		.launch-wrap, .launch-timer {
			display: none !important; }
		.launch-text {
			margin-bottom: 5px;
			font-size: 20px; }
		.launch-timer {
			display: none;
			font-size: 30px; }
		> div > .button {
			margin-top: 20px;
			margin-left: 0;
			font-size: 18px;

			padding: 13px 50px; } } }

.statistics-container {
	&:nth-child(2) {
		.statistic-item-wrap {
			background: #02027C; } }
	&.disabled {
		position: relative;
		.statistic-item-wrap {
			filter: blur(8px); }
		.lock {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 65px;
			i {
				z-index: 100; } } }
	.statistic-item-wrap {
		height: 100%;
		padding: 30px 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: linear-gradient(to bottom, #0B0562, #5F00FE);
		border-radius: 10px;
		div {
			width: 100%; }
		.text {
			text-align: center;
			font-size: 16px; }
		.number {
			line-height: 1;
			text-align: center;
			font-size: 45px;
			margin: 10px 0;
			span {
				font-size: 20px;
				margin-left: 10px; } }
		.winner {
			margin-top: 7px;
			.link {
				color: #ffffff;
				margin: 0 10px;
				text-decoration: none;
				width: 50%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis; }
			.win {
				width: auto;
				font-size: 20px;
				span {
					margin-left: 7px;
					font-size: 15px; } } } } }
.games-container {
	padding: 80px 0;
	.title {
		text-align: center;
		font-size: 40px; }
	.game {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		&-icon {
			height: 90px;
			margin-bottom: 0px;
			img {
				height: 100%; } }
		&-name {
			font-size: 23px;
			text-align: center;
			position: relative;
			text-transform: uppercase;
			&:after {
				z-index: -1;
				position: absolute;
				bottom: 6px;
				left: -2%;
				width: 104%;
				height: 7px;
				background-color: #0300A3;
				content: ""; } }
		&-text {
			word-wrap: none;
			font-size: 15px;
			text-align: center;
			margin: {
				top: 20px;
				bottom: 30px; }
			color: #fff; }
		&-link {
			width: 80%;
			text-align: center; } } }

footer {
	.right {
		@media only screen and (max-width: 768px) {
			text-align: right; } }
	.container {
		padding: {
			top: 20px;
			bottom: 5px; }
		@media only screen and (max-width: 768px) {
      padding-bottom: 20px; }
		position: relative;
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #4f4971;
			content: ""; } }
	.title {
		margin: {
			top: 10px;
			bottom: 5px; }
		font-size: 15px;
		color: #00f6ff; }
	a {
		margin-top: 3px;
		font-size: 14px;
		&:hover {
			color: #9F1777; } }
	p {
		font-size: 13px;
		text-align: center;
		color: #fff; }
	.logo {
		width: 60px;
		img {
			width: 100%; } } }
